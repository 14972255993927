.ui.grid.column-grid {
  margin-top: 0;
  margin-bottom: 0;
}

.homepage-grid .tile {
  border: 15px solid #ffaa14;
  height: 302px;
}

.tile-content {
  height: 100%;
  width: 100%;
  padding: 1rem;
  position: relative;
}

.homepage-grid .tile a {
  font-weight: 800;
  color: black;
}
.homepage-grid .tile img {
  position: absolute;
  bottom: 1rem;
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ui.grid>.row>[class*="six wide tablet"].column.homepage-grid,
  .ui.grid>.column.row>[class*="six wide tablet"].column.homepage-grid,
  .ui.grid>[class*="six wide tablet"].column.homepage-grid,
  .ui.column.grid>[class*="six wide tablet"].column.homepage-grid {
    width: 50% !important;
  }
}

.columns-view .ui.grid > .column-blocks-wrapper, .columns-view .ui.grid > .column-blocks-wrapper:not(.row) {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

#page-document .homepage-grid, #page-edit .homepage-grid {
  padding: 1rem !important;
}

@media only screen and (max-width: 767px) {
  #page-document .homepage-grid {
    padding: 1rem !important;
  }
}

#page-edit .tile-content img {
  width: auto !important;
}