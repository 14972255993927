.ths-button, #page-document button {
    box-shadow: 7px 7px 17px 0px #cccccc !important;
    transition: background-color 0.2s ease, color 0.2s ease !important;
    color: #8b2e89 !important;
    border: 1px solid #8b2e89 !important;
    background-color: white !important;
    font-weight: 700 !important;
    padding: 1rem 2rem !important;
    display: inline-block !important;
    border-radius: 0 !important;
}

.ths-button:hover, #page-document button:hover {
    color: white !important;
    border: 1px solid white !important;
    background-color: #8b2e89 !important;
}


a:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.ths-button::after {
  width: 17px;
  height: 17px;
  display: inline-block;
  margin-left: 1rem;
  content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTciIHZlcnNpb249IjEuMSIgdmlld0JveD0iMCAwIDQuNDk3OSA0LjQ5NzkiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAtMjkyLjUpIj4KICAgIDxwYXRoIGQ9Im0wLjQwMDg4IDI5Ni42IDAuNTIxMTYtMC41MjExNSIgc3R5bGU9ImZpbGw6bm9uZTtzdHJva2UtbGluZWNhcDpyb3VuZDtzdHJva2Utd2lkdGg6Ljc5Mzc1O3N0cm9rZTojOGIyZTg5Ii8+CiAgICA8cGF0aCBkPSJtMy41NzU5IDI5My40MiAwLjUyMTE2LTAuNTIxMTUiIHN0eWxlPSJmaWxsOm5vbmU7c3Ryb2tlLWxpbmVjYXA6cm91bmQ7c3Ryb2tlLXdpZHRoOi43OTM3NTtzdHJva2U6IzhiMmU4OSIvPgogICAgPHBhdGggZD0ibTAuNDAwODggMjkyLjkgMC41MjExNSAwLjUyMTE3IiBzdHlsZT0iZmlsbDpub25lO3N0cm9rZS1saW5lY2FwOnJvdW5kO3N0cm9rZS13aWR0aDouNzkzNzU7c3Ryb2tlOiM4YjJlODkiLz4KICAgIDxwYXRoIGQ9Im0zLjU3NTkgMjk2LjA4IDAuNTIxMTUgMC41MjExNyIgc3R5bGU9ImZpbGw6bm9uZTtzdHJva2UtbGluZWNhcDpyb3VuZDtzdHJva2Utd2lkdGg6Ljc5Mzc1O3N0cm9rZTojOGIyZTg5Ii8+CiAgPC9nPgo8L3N2Zz4K');
}
.ths-button:hover::after {
  width: 17px;
  height: 17px;
  content: url("data:image/svg+xml,%3Csvg width='17' height='17' version='1.1' viewBox='0 0 4.4979 4.4979' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='translate(0 -292.5)'%3E%3Cpath d='m0.40088 296.6 0.52116-0.52115' style='fill:none;stroke-linecap:round;stroke-width:.79375;stroke:%23ffffff'/%3E%3Cpath d='m3.5759 293.42 0.52116-0.52115' style='fill:none;stroke-linecap:round;stroke-width:.79375;stroke:%23ffffff'/%3E%3Cpath d='m0.40088 292.9 0.52115 0.52117' style='fill:none;stroke-linecap:round;stroke-width:.79375;stroke:%23ffffff'/%3E%3Cpath d='m3.5759 296.08 0.52115 0.52117' style='fill:none;stroke-linecap:round;stroke-width:.79375;stroke:%23ffffff'/%3E%3C/g%3E%3C/svg%3E");
}
