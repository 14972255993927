.grey {
    background-color: #e1e2e2;
    padding: 1rem;
    margin-bottom: 2rem;
}

.grey .block {
    margin-bottom: 0;
}

@media screen and (max-width: 767px) {
    .grey {
        padding: 2rem 1rem !important;
    }
    .grey .column {
        padding: 0 !important;
    }
    .grey h1{
        margin-left: -1rem;
    }
    .grey .columns-view h1{
        margin-left: 0;
    }
}